import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import storage from "../../../Storage";
import md5 from "md5";
import socket from "../../../Socket";
import C from "../../../Constant";
import {__, decode, encode, Event, fixDate, formatAmount, timeConvertor} from "../../../Helper";

class MyBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            betslist: [],
            game: 'crash',
            token: storage.getKey('token'),
            name: storage.getKey('name'),
            gameCode: md5('crash')
        };
    }

    componentDidMount() {
        socket.emit(C.MY_BETS, encode({token: this.state.token, game: this.state.game}));
        socket.on(C.MY_BETS, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addList(decode(data)));
    }

    addList(player) {
        if (player.name === this.state.name) {
            let row = <MyBetsList forMy={true} player={player}/>;
            this.setState(state => ({betslist: [row, ...state.betslist]}));
        }
    }

    makeList(arr) {
        if (typeof arr.history === "undefined") return;
        this.setState({loading: false});
        arr.history.forEach((player, i) => {
            let row = <MyBetsList key={i} forMy={true} player={player}/>;
            this.setState(state => ({betslist: [row, ...state.betslist]}));
        });
    }

    render() {
        if (this.state.betslist.length !== 0) {
            this.state.betslist.length = 10;
        }

        return (
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer sx={{maxHeight: 211}}>
                    <Table stickyHeader size="small" aria-label="player list">
                        <TableHead>
                            <TableRow>
                                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>REF</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>Time</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}} align="right">Stake [KES]</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}} align="left">&nbsp;&nbsp;&nbsp;&nbsp;@</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}} align="right">Profit [KES]</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.betslist}
                            {/*<TableRow key={1233} sx={{backgroundColor: '#000000eb'}}>
                                <TableCell className={'my-table-cell'} sx={{color: 'green'}} component="th" scope="row">1234567890</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: 'green'}} component="th" scope="row">2023-04-03 00:01:59</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: 'green'}} align="right">1,200</TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: 'green'}} align="left">
                                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}}
                                         className={'text-center text-success'}>10.23
                                    </div>
                                </TableCell>
                                <TableCell className={'my-table-cell'} sx={{color: 'green'}} align="right">12,276.00</TableCell>
                            </TableRow>
                            {rows.map((row) => (
                                <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}, backgroundColor: '#000000eb'}}>
                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffffcf'}} component="th" scope="row">{row.name}</TableCell>
                                    <TableCell className={'my-table-cell'} sx={{color: 'green'}} component="th" scope="row">2023-04-03 00:01:59</TableCell>
                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffffcf'}} align="right">{row.amount}</TableCell>
                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffffcf'}} align="left">
                                        <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}}
                                             className={'text-center'}>{row.cashout}</div>
                                    </TableCell>
                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffffcf'}} align="right">{row.won}</TableCell>
                                </TableRow>
                            ))}*/}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}

class MyBetsList extends React.Component {
    gameDetail = () => {
        //ask dan
        Event.emit('single_game_modal', {data: this.props.player})
    }

    render() {
        let {amount, profit, coin, created, gid, direct, cashout, forMy, option, result} = this.props.player;
        let isWinner = false;
        let date = fixDate(created);

        if (direct) {
            date = timeConvertor(created);
        }

        if (__.toNumber(profit) !== 0.00000000) {
            isWinner = true;
        }

        if (cashout === '' || cashout == null) {
            cashout = '🔥';
        }

        let row_color = isWinner ? 'green' : '#ffffffcf';

        return (
            <TableRow key={gid} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} component="th" scope="row">{gid}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} component="th" scope="row">{date}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="right">{formatAmount(amount, 0)}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="left">
                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                        {cashout}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="right">{formatAmount(profit, 0)}&nbsp;&nbsp;&nbsp;</TableCell>
            </TableRow>
        );
    }
}

export default MyBets;

/*function createData(name, amount, cashout, won, protein) {
    return {name, amount, cashout, won, protein};
}

const rows = [
    createData('1234567891', 159, 1.03, 259.23, 4.0),
    createData('1234567892', 237, 9.01, 3000.99, 4.3),
    createData('1234567893', 26, 16.0, 2, 400, 6.0),
    createData('1234567894', 10.01, 3.7, 67, 4.3),
    createData('1234567895', 356, 16.0, 49.13, 3.9),
    createData('1234567896', 356, 16.0, 49.13, 3.9),
    createData('1234567897', 356, 16.0, 49.13, 3.9),
    createData('1234567898', 356, 16.0, 49.13, 3.9),
    createData('1234567899', 356, 16.0, 49.13, 3.9),
];*/
