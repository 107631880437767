import * as React from 'react';
import Button from '@mui/material/Button';
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Withdrawal from "./Withdrawal";
import Depositing from "./Depositing";
import CreditsHeader from "./CreditsHeader";
import {Component} from "react";
import storage from "../../../../Storage";
import {CONTACT_NUMBER, Event, sendNotification} from "../../../../Helper";
import Confirm from "./Confirm";
import {Modal} from "react-bootstrap";

class WalletDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
            initState: 0,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('update_payment', () => {
            this.setState({show: false});
        })
        Event.on('show_deposit', () => {
            this.setState({initState: 0});
            this.handleShow();
        })
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow() {
        if (storage.getKey('token') === null) {
            return sendNotification('Please Login to use this option.', 'warning', 'top-right');
        }

        this.setState({show: true, effect: 'pulse'});
    }

    render() {
        let {show, effect} = this.state;

        return (
            <div>
                <Button className={'bg-image-custom'} style={{color: 'white'}} onClick={this.handleShow} size={'small'} name={'wallet'}>
                    <span style={{fontSize: '0.6rem', alignItems: 'center', display: 'inline-flex', fontWeight: 'bold'}}>Deposit&nbsp;/&nbsp;Withdraw</span>
                </Button>

                <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} backdrop={"static"} show={show} onHide={this.handleClose}
                       aria-labelledby="promo-md-modal"
                       className={'animated ' + effect}>
                    <Modal.Header className={'p-1'} closeButton>
                        <Modal.Title style={{color: 'antiquewhite', fontSize: '1.2rem'}}>
                            <RequestQuoteIcon/>&nbsp;Wallet | {this.state.phone}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{fontSize: '0.81rem'}} className={'m-1'}>
                        <CreditsHeader/>

                        <div className="row">
                            <div className={'col-12 p-0'}>
                                <div className={"my-gradient"}>
                                    <Tabs>
                                        <TabList>
                                            <Tab><span className={'text-white'}>Deposit</span></Tab>
                                            <Tab><span className={'text-white'}>Withdraw</span></Tab>
                                            <Tab><span className={'text-white'}>Verify Deposit</span></Tab>
                                        </TabList>
                                        <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                            <Depositing/>
                                        </TabPanel>
                                        <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                            <Withdrawal/>
                                        </TabPanel>
                                        <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                            <Confirm/>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={'p-2'}>
                        For more, call: {CONTACT_NUMBER}
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default WalletDialog;