import React, {Component} from "react";

import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LockIcon from '@mui/icons-material/Lock';
import socket from "../../../Socket";
import C from "../../../Constant";
import {decode, encode, wait} from "../../../Helper";
import {doLogin} from "../../../Functions";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: '',
            status: false,
            status_state: 'warning',
            submitted: false,
            disabled: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
    }

    setLogin = (data) => {
        if (data.status === true) {
            this.setState({status: "Account validated successfully...", status_state: 'success'});
            doLogin(data);
        } else {
            this.setState({status: data.status, status_state: 'warning', submitted: false, disabled: false});
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({submitted: true, disabled: true, status: "Checking account...", status_state: 'warning'});

        const {phone, password} = this.state;

        if (!(phone && password)) {
            this.setState({disabled: false, status: false, status_state: 'warning'});
            return;
        }

        wait(1000).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                phone: phone,
                password: password,
                recaptcha: 'google'
            }));
        })
    }

    render() {
        return (
            <>
                <form style={{display: 'contents'}} onSubmit={(e) => {
                    this.handleSubmit(e)
                }}>
                    <div className={'custom-card m-2 w-100'}>
                        <span className={'text-white custom-label'}>Mobile&nbsp;&nbsp;</span>
                        <input required placeholder={'-- e.g. 0722000000 --'} type={"number"} id={"mobile-number"} className={"text-strong form-control input-number custom-input"}
                               value={this.state.phone} autoComplete={'off'} onChange={e => this.setState({phone: e.target.value})} name={"username"}/>
                        <PhoneAndroidIcon className={'text-white'}/>
                    </div>
                    {this.state.submitted && !this.state.phone && <div className="help-block text-danger ml-2">{"Mobile is required"}</div>}

                    <div className={'custom-card m-2 w-100'}>
                        <span className={'text-white custom-label'}>Password&nbsp;&nbsp;</span>
                        <input required autoComplete={'off'} type={"password"} id={"password"} className={"text-strong form-control input-number custom-input"} name={"password"}
                               value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
                        <LockIcon className={'text-white'}/>
                    </div>

                    <Button size={'large'} variant="contained" className={'custom-button m-2'} type={'submit'} fullWidth sx={{m: 2}}
                            disabled={this.state.disabled}><LoginIcon/>&nbsp;Login</Button>
                </form>

                {this.state.status &&
                    <span style={{fontSize: '.7rem'}} className={"text-" + this.state.status_state + " w-100 text-center"}>{this.state.status}</span>
                }
                <div style={{borderTop: '1px solid #80808063'}} className={'col-12 text-center mb-3 mt-3'}>
                    <table width={'100%'}>
                        <tr>
                            <td><Link className={'text-white'} style={{textDecoration: 'none', fontSize: '12px'}} to={'/signup'}>New? <span
                                style={{fontWeight: 'bold'}}>Register</span></Link></td>
                            <td><Link className={'text-white'} style={{textDecoration: 'none', fontWeight: 'bold', fontSize: '12px'}} to={'/forgot'}>Forgot Password</Link></td>
                        </tr>
                    </table>
                </div>

            </>
        );
    }
}

export default Login;