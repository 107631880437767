import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import socket from "../../../Socket";
import {Event, decode, BRAND, formatAmount, fixDate} from "../../../Helper";
import {Divider, Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

class BannerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
            loading: true,
            players: [],
            nyakuas: []
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.on('PROMO_WINNERS', data => this.winners(decode(data)));
        socket.on('NYAKUA_WINNERS', data => this.nyakuaWinners(decode(data)));
        Event.on('show_banner_modal', this.handleShow);
    }

    handleShow(e) {
        socket.emit('PROMO_WINNERS');
        socket.emit('NYAKUA_WINNERS');
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    winners = (data) => {
        this.setState({loading: false, players: data})
    };

    nyakuaWinners = (data) => {
        this.setState({loading: false, nyakuas: data})
    };

    render() {
        let {show, effect} = this.state;
        const list = this.state.players.map((player, i) =>
            <Players key={i} index={i + 1} player={player}/>
        );

        const nyakua_list = this.state.nyakuas.map((nyakua, i) =>
            <Nyakuas key={i} index={i + 1} nyakua={nyakua}/>
        );

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} backdrop="static" show={show} onHide={this.handleClose}
                   aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-2'} closeButton>
                    <Modal.Title style={{color: 'antiquewhite', fontSize: '1.2rem'}}>
                        {BRAND} Promo -- Nyakua 5k Daily!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontSize: '0.81rem'}} className={'p-2'}>

                    <Divider textAlign={"left"} style={{color: 'antiquewhite'}}>Promotion Mechanics</Divider>

                    <ol style={{paddingLeft: '1rem'}}>
                        <li>This Promotion is based on Daily number of Plays</li>
                        <li>The Minimum Stake to qualify is <strong className={'text-warning'}>KES 20</strong></li>
                        <li>The more you play, the higher you stand a chance of winning Daily</li>
                        <li>Top 5 players with the most plays in a day will be awarded</li>
                        <li>The highest player will be rewarded <strong className={'text-warning'}>KES 5,000</strong> daily</li>
                        <li>The rest of the players will each win <strong className={'text-warning'}>KES 500</strong> daily</li>
                        <li>The promotion runs between 00:00 and 23:59 daily</li>
                        <li>All winners will have their wallets credited at the end of the day</li>
                    </ol>

                    <Divider textAlign={"left"} style={{color: 'antiquewhite'}}>Nyakua 5k Winners (<strong className={'text-warning'}>Users awarded so far: {formatAmount(nyakua_list.length, 0)}</strong>)</Divider>

                    <Paper sx={{width: '100%', overflow: 'hidden'}}>
                        <TableContainer sx={{maxHeight: 211}}>
                            <Table style={{minHeight: '80px'}} stickyHeader size="small" aria-label="player list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Date</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Promotion</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>User</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Plays[24hr]</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Won[KES]</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {nyakua_list.length === 0 ? "[Winners will be displayed here]" : nyakua_list}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <Divider className={'mt-2'} textAlign={"left"} style={{color: 'antiquewhite'}}>Weekly Promo Winners (<strong className={'text-warning'}>Users awarded so far: {formatAmount(list.length, 0)}</strong>)</Divider>

                    <Paper sx={{width: '100%', overflow: 'hidden'}}>

                        <TableContainer sx={{maxHeight: 211}}>
                            <Table style={{minHeight: '80px'}} stickyHeader size="small" aria-label="player list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Date</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Promotion</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>User</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Plays</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Won[KES]</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    T&C Apply
                </Modal.Footer>
            </Modal>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, index} = this.props;

        let username = player.name;
        let promotion = player.promotion;
        let narration = player.narration;
        let created = player.created;
        let amount_won = formatAmount(player.amount_won, 0);

        let row_color = (player.amount_won >= 5000) ? 'green': '#ffffffcf';

        return (
            <TableRow key={index} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{fixDate(created).substring(0, 16)}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{promotion}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{username}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="left">
                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                        {narration}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{amount_won}&nbsp;&nbsp;&nbsp;</TableCell>
            </TableRow>
        );
    }
}

class Nyakuas extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {nyakua, index} = this.props;

        let username = nyakua.name;
        let promotion = nyakua.promotion;
        let narration = nyakua.narration;
        let created = nyakua.created;
        let amount_won = formatAmount(nyakua.amount_won, 0);

        let row_color = (nyakua.amount_won >= 5000) ? 'green': '#ffffffcf';

        return (
            <TableRow key={index} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{fixDate(created).substring(0, 16)}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{promotion}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{username}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="left">
                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                        {narration}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{amount_won}&nbsp;&nbsp;&nbsp;</TableCell>
            </TableRow>
        );
    }
}

export default BannerModal;