import React, {Component} from "react";
import {Box, Grid} from "@mui/material";
import socket from "../../../Socket";
import {decode, formatAmount} from "../../../Helper";
import moment from "moment/moment";
import Countdown from "react-countdown";

class TopPlays extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
    }

    componentDidMount() {
        if (this._isMounted) {
            socket.emit('TOP_PLAYS');
            socket.on('TOP_PLAYS', data => this.setTopPlays(decode(data)));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setTopPlays(data) {
        this.setState({items: data});
    }

    render() {
        let current_hour = parseInt(moment().format("HH"));
        let current_date = moment().format("YYYY-MM-DD") + "T01";
        let soon = false;
        let soon_text = 'soon!';

        if (current_hour >= 13 && current_hour <= 15) { //3pm
            current_date = moment().format("YYYY-MM-DD") + "T15";
        } else if (current_hour >= 18 && current_hour <= 20) { //8pm
            current_date = moment().format("YYYY-MM-DD") + "T20";
        } else if (current_hour >= 22 && current_hour <= 23) { //1am
            let current_day = parseInt(moment().format("DD"));
            current_date = moment().format("YYYY-MM-") + (current_day + 1) + "T01";
        } else if (current_hour >= 0 && current_hour <= 1) { //1am
            current_date = moment().format("YYYY-MM-DD") + "T01";
        } else {
            soon = true;
            if (current_hour >= 2 && current_hour <= 12) {
                soon_text = '@1pm!';
            } else if (current_hour >= 17 && current_hour <= 17) {
                soon_text = '@6pm!';
            } else if (current_hour === 21) {
                soon_text = '@10pm!';
            }
        }

        const list = this.state.items.map((item, i) =>
            <Items key={i} index={i + 1} item={item}/>
        );

        return (
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={0}>
                    <Grid style={{display: 'inline-flex'}} xs={10}>
                        Top Players:&nbsp;{list.length === 0 ? <div>&nbsp;|&nbsp;Loading...</div> : list}
                    </Grid>
                    {soon ? <div className={'text-warning'}>Starts {soon_text}</div> : <Countdown zeroPadTime={2} date={current_date + ":59:59"} renderer={renderer}/>}
                </Grid>
            </Box>
        );
    }
}

class Items extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {item} = this.props;

        let username = item.name;
        let plays = item.plays;

        return (
            <Grid xs={4}>
                {username}: <span style={{color: '#FFBB27', fontWeight: 'bold'}}>{formatAmount(plays, 0)}</span>
            </Grid>
        );
    }
}

const renderer = ({formatted}) => {
    return (
        <Grid style={{display: 'inline-flex'}} xs={2}>
            <div className={'top-count-down'}>{formatted.hours}</div>
            :
            <div className={'top-count-down'}>{formatted.minutes}</div>
            :
            <div className={'top-count-down'}>{formatted.seconds}</div>
        </Grid>
    );
};

export default TopPlays;