import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import socket from "../../../Socket";
import {Event, decode, BRAND, formatAmount, fixDate} from "../../../Helper";
import {Divider, Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

class BannerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
            loading: true,
            players: [],
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.on('PROMO_WINNERS', data => this.winners(decode(data)));
        Event.on('show_happy_hour', this.handleShow);
    }

    handleShow(e) {
        socket.emit('PROMO_WINNERS', 'HAPPY HOUR');
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    winners = (data) => {
        this.setState({loading: false, players: data})
    };

    render() {
        let {show, effect} = this.state;
        const list = this.state.players.map((player, i) =>
            <Players key={i} index={i + 1} player={player}/>
        );

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} backdrop="static" show={show} onHide={this.handleClose}
                   aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-2'} closeButton>
                    <Modal.Title style={{color: 'antiquewhite', fontSize: '1.2rem'}}>
                        {BRAND} Promo -- Happy Hour!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontSize: '0.81rem'}} className={'p-2'}>

                    <Divider textAlign={"left"} style={{color: 'antiquewhite'}}>Promotion Mechanics</Divider>

                    <ol style={{paddingLeft: '1rem'}}>
                        <li>Promotions runs everyday between <strong className={'text-warning'}>1PM</strong> to <strong className={'text-warning'}>3PM</strong>; <strong className={'text-warning'}>6PM</strong> to <strong className={'text-warning'}>8PM</strong> & <strong className={'text-warning'}>10PM</strong> to <strong className={'text-warning'}>1AM</strong></li>
                        <li><strong className={'text-warning'}>Two</strong> top players will be awarded in each of the happy hour promotions</li>
                        <li>Top player for the promotions between 1PM to 3PM & 6PM to 8PM will win <strong className={'text-warning'}>KES 2,000</strong> while number two will win <strong className={'text-warning'}>KES 500</strong></li>
                        <li>Top player for the promotion running between 10PM to 1AM will win <strong className={'text-warning'}>KES 3,000</strong> while number two will win <strong className={'text-warning'}>KES 1,000</strong></li>
                        <li>Winners must have <strong className={'text-warning'}>most play</strong></li>
                        <li>Minimum deposit required to take part is <strong className={'text-warning'}>KES 50</strong></li>
                        <li>The minimum stake required to take part is <strong className={'text-warning'}>KES 50</strong></li>
                        <li>Winning amount to be credited to the winners <strong className={'text-warning'}>wallets</strong></li>
                        <li>If there's a tie, the money is <strong className={'text-warning'}>split</strong> between the two top winners.</li>
                    </ol>

                    <Divider className={'mt-2'} textAlign={"left"} style={{color: 'antiquewhite'}}>Happy Hour Winners</Divider>

                    <Paper sx={{width: '100%', overflow: 'hidden'}}>

                        <TableContainer sx={{maxHeight: 211}}>
                            <Table style={{minHeight: '80px'}} stickyHeader size="small" aria-label="player list">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Date</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Promotion</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>User</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Plays</TableCell>
                                        <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Won[KES]</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    T&C Apply
                </Modal.Footer>
            </Modal>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, index} = this.props;

        let username = player.name;
        let promotion = player.promotion;
        let narration = player.narration;
        let created = player.created;
        let amount_won = formatAmount(player.amount_won, 0);

        let row_color = (player.amount_won >= 5000) ? 'green' : '#ffffffcf';

        return (
            <TableRow key={index} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{fixDate(created).substring(0, 16)}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{promotion}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{username}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="left">
                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                        {narration}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{amount_won}&nbsp;&nbsp;&nbsp;</TableCell>
            </TableRow>
        );
    }
}

export default BannerModal;